import React, { Fragment, useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import LogoImage from "../images/logo-web.png";
import Burger from "./burger";

const TopHeader = styled.header`
  padding: 2rem 0;
`;

const Footer = styled.footer`
  margin-top: 5rem;
  padding: 2rem 0;
  background-color: #3e3e3e;

  a {
    color: #adadad;
    font-size: 1rem;
  }
`;

const Container = styled.div`
  padding: 0 1rem;
  max-width: 1024px;
  margin: 0 auto;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Copyright = styled.p`
  color: #adadad;
  font-size: 1rem;
  text-align: center;
`;

const Logo = styled((props) => <Link {...props} />)`
  display: block;

  img {
    display: block;
    max-width: 180px;

    @media (max-width: 640px) {
      max-width: 180px;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background: #fff;
    flex-direction: column;
    z-index: 5;
    justify-content: flex-start;
    padding: 2rem 0;
    transition: 0.3s ease-in-out;
    box-shadow: 10px 0px 30px #c3c3c382;
    visibility: ${({ open }) => (open ? "visible" : "hidden")};
    transform: translateX(${({ open }) => (open ? "0" : "-100%")});
  }
`;

const NavLink = styled((props) => <Link {...props} />)`
  color: #17130f;
  margin-right: 1.7rem;
  text-decoration: none;
  font-size: 1.2rem;

  &[aria-current="page"] {
    border-bottom: 2px solid;
  }

  @media (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const NavLinkSpecial = styled((props) => <Link {...props} />)`
  color: #17130f;
  margin-right: 2rem;
  text-decoration: none;
  font-size: 1.2rem;
  background: #f6e1de;
  padding: 0.5rem 1rem;
  border-radius: 50px;

  &:hover {
    background: #ffcccf;
  }

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const NavBurger = styled((props) => <Burger {...props} />)`
  display: none;

  @media (max-width: 900px) {
    display: flex;
  }
`;

export default function Layout({ children, location }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Fragment>
      <TopHeader>
        <Container>
          <Flex>
            <Logo to="/">
              <img src={LogoImage} alt="The Stanley Bakes" />
            </Logo>
            <NavBurger open={open} setOpen={setOpen} />
            <Nav open={open}>
              <NavLink to="/cakes/">Celebration Cakes</NavLink>
              <NavLink to="/cupcakes/">Cupcakes</NavLink>
              <NavLink to="/wedding-cakes-rugby/">Wedding Cakes</NavLink>
              <NavLinkSpecial to="/contact/">Book a Cake</NavLinkSpecial>
            </Nav>
          </Flex>
        </Container>
      </TopHeader>
      <main id="main" aria-live="assertive">
        {children}
      </main>
      <Footer>
        <Container>
          <Flex>
            <Copyright>© 2022 The Stanley Bakes</Copyright>
            <a
              href="https://www.instagram.com/thestanleybakes/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </Flex>
        </Container>
      </Footer>
    </Fragment>
  );
}
